@font-face {
    font-family: "Share Tech";
    src: url("../assets/fonts/share-tech/share-tech.otf");
}
@font-face {
    font-family: "Share Tech Mono";
    src: url("../assets/fonts/share-tech/ShareTechMono-Regular.ttf");
}
@font-face {
    font-family: "Nostromo Regular Light";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Light.otf");
}
@font-face {
    font-family: "Nostromo Regular Medium";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Medium.otf");
}
@font-face {
    font-family: "Nostromo Regular Bold";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Bold.otf");
}
@font-face {
    font-family: "Nostromo Regular Black";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Black.otf");
}
@font-face {
    font-family: "Nostromo Regular Heavy";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Heavy.otf");
}
@font-face {
    font-family: "Rajdhani Light";
    src: url("../assets/fonts/rajdhani/Rajdhani-Light.ttf");
}
@font-face {
    font-family: "Rajdhani Regular";
    src: url("../assets/fonts/rajdhani/Rajdhani-Regular.ttf");
}
@font-face {
    font-family: "Rajdhani Medium";
    src: url("../assets/fonts/rajdhani/Rajdhani-Medium.ttf");
}
@font-face {
    font-family: "Rajdhani Semibold";
    src: url("../assets/fonts/rajdhani/Rajdhani-SemiBold.ttf");
}
@font-face {
    font-family: "Rajdhani Bold";
    src: url("../assets/fonts/rajdhani/Rajdhani-Bold.ttf");
}

@font-face {
    font-family: "Source Sans Pro Black";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-Black.otf");
}

@font-face {
    font-family: "Source Sans Pro Black It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-BlackIt.otf");
}

@font-face {
    font-family: "Source Sans Pro Bold";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-Bold.otf");
}

@font-face {
    font-family: "Source Sans Pro Bold It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-BoldIt.otf");
}

@font-face {
    font-family: "Source Sans Pro Extra Light";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-ExtraLight.otf");
}

@font-face {
    font-family: "Source Sans Pro Extra Light It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-ExtraLightIt.otf");
}

@font-face {
    font-family: "Source Sans Pro It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-It.otf");
}

@font-face {
    font-family: "Source Sans Pro Light";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-Light.otf");
}

@font-face {
    font-family: "Source Sans Pro Light It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-LightIt.otf");
}

@font-face {
    font-family: "Source Sans Pro Regular";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-Regular.otf");
}

@font-face {
    font-family: "Source Sans Pro Semibold";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-Semibold.otf");
}

@font-face {
    font-family: "Source Sans Pro Semibold It";
    src: url("../assets/fonts/sourceSansPro/SourceSansPro-SemiboldIt.otf");
}

/* Update `src/containers/dimensions.ts` */
html {
    /* By default, 1rem = 16px */
    /* 62.5% of 16px is 10px, it means now 1rem is 10px */
    font-size: 57%;
}

@media only screen and (max-width: 1922px) {
    html {
        font-size: 50%;
    }
}

@media only screen and (max-width: 1500px) {
    html {
        font-size: 48%;
    }
}

@media only screen and (max-width: 1300px) {
    html {
        font-size: 44%;
    }
}

@media only screen and (max-width: 900px) {
    html {
        font-size: 42%;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 37%;
    }
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
}
span {
    font-family: inherit;
    font-size: inherit;
}

body {
    overflow: hidden; /* Hide scrollbars */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin !important;
    scrollbar-color: #00ffff50 #ffffff !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 1rem !important;
    height: 0.6rem !important;
}

*::-webkit-scrollbar-track {
    background: #ffffff15 !important;
}

*::-webkit-scrollbar-thumb {
    background: #00ffff50 !important;
    border-radius: 0px !important;
    border: 0.5px ridge #00ffff20 !important;
}

*::-webkit-scrollbar-thumb:hover {
    background: #00ffff90 !important;
}

/* Input number arrows */
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: #00ffff;
}

button,
button * {
    user-select: none;
}
